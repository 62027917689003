
import React, { useState, useEffect } from 'react'
import './App.css'
import axios from 'axios';
import { Helmet } from "react-helmet";

function App() {

  // Schema.org structured data for local business
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "HeedSites",
    "description": "Heedsites – Your Vision, Our Innovation. Professional web and mobile app development company specializing in custom solutions for businesses.",
    "image": "./assets/logo-dark.png",
    "url": "https://heedsites.com",
    "address": {
      "@type": "PostalAddress",
      "addressCountry": "India"
    },
    "telephone": "8919907863",
    "email": "heedsites@gmail.com",
    "priceRange": "$$",
    "sameAs": [
      "https://www.linkedin.com/company/heedsites/",
      // "https://twitter.com/heedsites",
      // Add your social media URLs
    ],
    "serviceType": [
      "Web Development",
      "Mobile App Development",
    ]
  };

  var [isSticky, setSticky] = useState(false);

  var [form, setForm] = useState({ name: "", mail: "", message: "", mobile: "" })
  var [errors, setErrors] = useState(false)
  const [buttonStatus, setButtonStatus] = useState('Send')

  const handleScroll = () => {
    isSticky = window.scrollY > 0
    setSticky(isSticky);
  };

  useEffect(() => {
    setButtonStatus('Send')
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const submitForm = () => {

    setButtonStatus('Sending')
    if (form.message.length > 0) {
      axios.post('https://arikya.in/api/techloomMail', form)
        .then((response) => console.log(response),

          setButtonStatus("Sent")
        )
        .catch((error) => console.log(error));
    }
    else {
      setErrors(true);
      setButtonStatus('Send')
    }
  }


  return (
    <div className='body'>
      <Helmet>
        <title>HeedSites | Your Vision, Our Innovation.</title>
        <meta name="description" content="Transform your business with HeedSites.Partner with HeedSites for innovative Web and Mobile applications, and technical resource solutions that drive business growth.Get custom-built, scalable applications." />
        <meta name="keywords" content="Heedsites, HeedSites, HEEDSITES, heedsites, web development, mobile app development, custom websites, technical resources, software development, React development, iOS apps, Android apps, web design, IT consulting" />

        {/* Open Graph tags for social sharing */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="HeedSites | Your Vision, Our Innovation." />
        <meta property="og:description" content="Transform your business with HeedSites' Partner with HeedSites for innovative Web and Mobile applications, and technical resource solutions that drive business growth. Get custom-built, scalable applications." />
        <meta property="og:image" content="./assets/logo.png" />
        <meta property="og:url" content="https://heedsites.com" />

        {/* Twitter Card tags
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="HeedSites | Expert Web & Mobile Development Solutions" />
        <meta name="twitter:description" content="Custom web development, mobile apps, and technical resources tailored for your business success." />
        <meta name="twitter:image" content="https://heedsites.com/assets/social-image.jpg" /> */}

        {/* Additional SEO meta tags */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="HeedSites" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://heedsites.com" />

        {/* Structured data injection */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>

      </Helmet>
      {
        isSticky && <div className="header">
          <img className='logo' alt="Heedsites Logo" src={require('./assets/logo.png')} />
        </div>
      }


      <div className='background' style={{ textAlign: "center", color: "white" }}>
        <div className='content'>
          <header style={{ fontSize: "1.7rem" }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img className='logo' src={require('./assets/logo-dark.png')} alt="HeedSites logo" /><div className='logoFont'>EEDSITES</div>
            </div>
          </header>
          <div style={{ fontSize: "1rem" }}>Your Vision, Our Innovation.</div>
          <div className='center'>
            <div>
              <div><h1 className='heading'>Enhance Your<br /> Online Presence</h1></div>
              <div className='sub-text'>Take your business to the next level with our expert web and mobile app development services.</div>
              <br></br>

              <button className='btn' style={{ backgroundColor: "#ACC8FF", padding: "0.7rem", fontSize: "1.3rem" }}>Get Started</button>
            </div>
          </div>
        </div>
      </div>


      <div className='sub-body'>


        <div className='sub-text1'>
          "Are you looking for top-notch web and app development services? Look no further than HeedSites. Our team of skilled developers is dedicated to creating innovative and user-friendly websites that will help your business stand out online. With a focus on quality and customer satisfaction, HeedSites is the partner you need to take your web presence to the next level."
        </div>



        <div className='services'>


          <h1 className='services-heading'>Services we provide</h1>

          <div className='row justify-content-center'>
            <div className='col-sm-12 col-lg-6 ' >
              <div className='image1 overlay' >
                <h5>Web Application </h5>
                <span>Expert development of scalable web applications to meet your specific business needs.</span>
              </div>
            </div>

            <div className='col-sm-12 col-lg-6' >
              <div className='image2 overlay'>
                <h5>Custom  Responsive Websites</h5>
                <span>Tailored web designs to represent your brand and engage your audience.</span>
              </div>
            </div>

            <div className='col-sm-12 col-lg-6' >
              <div className='image3 overlay'>
                <h5>Mobile App</h5>
                <span>Transforming concepts into captivating apps that redefine user experiences.</span>
              </div>
            </div>


          </div>



        </div>


        <div className='about'>

          <div className='row align-items-center'>
            <div className='col-sm-12 col-lg-6'>
              <h1 className='services-heading'>About us</h1><br></br>
              <span className='about-text'>HeedSites is start-up company . With a team of highly skilled professionals, we specialize in creating innovative and user-friendly websites and mobile apps that empower businesses to thrive in the digital world.
                <br></br><br></br>    At HeedSites, we believe in the power of technology to transform businesses. Our goal is to provide our clients with cutting-edge web and mobile solutions that not only meet their unique requirements but also exceed their expectations. With our expertise in web and app development, we help businesses establish a strong online presence and drive growth through effective digital strategies.<br /><br /><br /></span>
            </div>
            <div className='col-sm-12 col-lg-6'>
              <img src={require('./assets/Techloom.jpeg')} className="techloomImage" style={{ borderRadius: "13px" }} />
            </div>
          </div>


        </div>


        <div className='contact'>

          <div className='row align-items-center'>
            <div className='col-sm-12 col-lg-6'>
              <h1 style={{ fontWeight: "800", fontSize: "2.2rem" }}>Contact Us Today</h1>
              <span className='contact-text'>Fill out the form below to get in touch with us. We are here to answer any questions you may have and provide you with the best web development solutions for your business.<br /><br /></span>
            </div>
            <div className='col-sm-12 col-lg-6'>
              <div>
                <span>Name</span><br />
                <input onChange={(e) => { setForm({ ...form, name: e.target.value }) }} className='form-control shadow-none' /><br />
              </div>

              <div>
                <span>Email</span><br />
                <input onChange={(e) => { setForm({ ...form, mail: e.target.value }) }} className='form-control shadow-none' /><br />
              </div>

              <div>
                <span>Mobile No</span><br />
                <input onChange={(e) => { setForm({ ...form, mobile: e.target.value }) }} className='form-control shadow-none' /><br />
              </div>

              <div>
                <span>Message</span><br />
                <textarea onChange={(e) => { setErrors(false); setForm({ ...form, message: e.target.value }) }} className='form-control shadow-none' ></textarea>
                {errors && <span style={{ color: "crimson" }}>Please type the message </span>}
                <br />
              </div>


              <button className='btn btn-primary' onClick={submitForm} style={{ float: "right", width: "150px" }}>{buttonStatus}</button>

            </div>
          </div>


        </div>

        <div className='footer'>
          <img className='logo' alt="Heedsites Logo" src={require('./assets/logo-dark.png')} /><div>HeedSites</div>
        </div>

      </div>
    </div>
  )
}

export default App